import React, { useState, useEffect, createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const LocationsContext = createContext()

const LocationsProvider = ({ children }) => {
  const GlobalValues = {
    id: "-70as3c91-8asd8-58fa-b554-dc6askldfjb83f",
    countryName: "GL",
    countryCode: "gl",
  }

  const [locations, setLocations] = useState([GlobalValues]) // array of objects
  const [currentLocation, setCurrentLoaction] = useState(GlobalValues) // object
  const [currentCountryName, setCurrentCountryName] = useState()
  const [countryCode, setCountryCode] = useState(0)
  const [ipAddress, setIpAddress] = useState()

  const {
    allSanityLocations: { edges: locationsData },
  } = useStaticQuery(graphql`
    query allLocationsQuery {
      allSanityLocations {
        edges {
          node {
            id
            countryName
            countryCode
            currencyCode
            currencySymbol
            countryGrouping
          }
        }
      }
    }
  `)

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(resultData => {
        setCountryCode(resultData.country_code)
        setCurrentCountryName(resultData.country_name)
        setIpAddress(resultData.ip)
        //loop current locations for matching location
        for (let index = 0; index < locationsData.length; index++) {
          const location = locationsData[index]
          if (
            location.node.countryCode.toUpperCase() === resultData.country_code
          ) {
            setCurrentLoaction(location.node)
          }
          //Check other countries
          location.node.countryGrouping.map(cluster => {
            if (cluster === resultData.country_code) {
              setCurrentLoaction(location.node)
            }
          })
        }
      })

    setLocations([
      ...locations, // add global/default location as the first element of the array
      ...locationsData // put rest loading country/location data to the following,
        .map(location => {
          return { ...location.node }
        }) //reconstruct data stucture to match first one, make it simple to use
        .sort((a, b) => (a.countryName > b.countryName ? 1 : -1)), //sort alphabetically based on country name
    ])
  }, [])

  return (
    <LocationsContext.Provider
      value={{ locations, currentLocation, setCurrentLoaction, ipAddress }}
    >
      {children}
    </LocationsContext.Provider>
  )
}

export default LocationsProvider
