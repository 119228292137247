import React from "react"
import LocationsProvider from "./src/contexts/locationsContext.js"

if (process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
}

export const wrapRootElement = ({ element }) => {
  return <LocationsProvider>{element}</LocationsProvider>
}
